import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet";
import Logo from "../images/GSoftware-logo.png";

const Header = function({ lang }) {
  const [menuHidden, toggleMenu] = useState(true);
  const menu = React.createRef();
  const navbar = React.createRef();
  const header = React.createRef();

  useEffect(() => {
    if(!menuHidden) 
      menu.current.classList.add('show');
    else
      menu.current.classList.remove('show');
    if(typeof window !== `undefined`) {
      window.addEventListener('scroll', () => {
        if(navbar.current !== null)
          if(window.scrollY > 0) {
            navbar.current.classList.add('fixed-top');
          } else {
            navbar.current.classList.remove('fixed-top');
          }
      });
    }
  });

  function watchForActive(elementToChange, triggerElement) {
    if(typeof window !== `undefined`) {
      window.addEventListener('scroll', () => {
        const DOMRect = document.querySelector(triggerElement).getBoundingClientRect();
        if(window.innerWidth >= 992) {
          if(document.documentElement.scrollTop < 30)
            DOMRect.y -= 113;
          else
            DOMRect.y -= 83;
        } else {
          DOMRect.y -= 71;
        }
        if(elementToChange !== null)
          if(DOMRect.y <= 0 && Math.abs(DOMRect.y) < DOMRect.height) elementToChange.classList.add('active');
          else elementToChange.classList.remove('active');
      });
    }
  }

  const data = useStaticQuery(graphql`
    query {
      wp {
        logoUrl
      }
      allWpMediaItem {
        edges {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 190, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mediaItemUrl
          }
        }
      }
    }
  `);

  const logo = data.allWpMediaItem.edges.filter(element => element.node.mediaItemUrl === data.wp.logoUrl);

  const translations = {
    pl: {
      label: 'Wybierz język',
      menu: [
        'Start',
        'O nas',
        'Nasze usługi',
        'Projekty',
        'Kontakt'
      ]
    },
    en: {
      label: 'Choose language',
      menu: [
        'Let\'s start!',
        'About us',
        'Services',
        'Our work',
        'Contact'
      ]
    }
  };

  return (
    <header id="header" ref={header} className="navbar-empty-space">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark" ref={navbar}>
        <a className="navbar-brand" href="#top">
          { logo[0] ? <Img fluid={logo[0].node.localFile.childImageSharp.fluid} alt="GSoftware Logo" /> : <img src={Logo} alt="GSoftware Logo" />}
        </a>

        <button className="navbar-toggler" type="button" aria-controls="headerMenu" aria-expanded={!menuHidden} aria-label="Toggle navigation" onClick={() => toggleMenu(!menuHidden)} onMouseDown={e => e.preventDefault()}>
          { menuHidden && <FontAwesomeIcon icon={faBars} style={{ fontSize: '1.8rem' }} /> }
          { !menuHidden && (
            <>
              <FontAwesomeIcon icon={faTimes} style={{ fontSize: '1.8rem' }} />
              <Helmet bodyAttributes={{
                class: 'overflow-hidden'
              }} />
            </>
          ) }
        </button>

        <div className="navbar-collapse" id="headerMenu" ref={menu}>
          <ul className="navbar-nav ml-lg-auto">
            <li className="nav-item" ref={(element) => watchForActive(element, '#lets-start')}>
              <a className="nav-link" href="#lets-start" onClick={() => toggleMenu(true)}>{translations[lang].menu[0]}</a>
            </li>
            <li className="nav-item" ref={(element) => watchForActive(element, '#about-us')}>
              <a className="nav-link" href="#about-us" onClick={() => toggleMenu(true)}>{translations[lang].menu[1]}</a>
            </li>
            <li className="nav-item" ref={(element) => watchForActive(element, '#services')}>
              <a className="nav-link" href="#services" onClick={() => toggleMenu(true)}>{translations[lang].menu[2]}</a>
            </li>
            <li className="nav-item" ref={(element) => watchForActive(element, '#our-work')}>
              <a className="nav-link" href="#our-work" onClick={() => toggleMenu(true)}>{translations[lang].menu[3]}</a>
            </li>
            <li className="nav-item" ref={(element) => watchForActive(element, '#contact')}>
              <a className="nav-link" href="#contact" onClick={() => toggleMenu(true)}>{translations[lang].menu[4]}</a>
            </li>
            <li className="nav-item flags"><Link to="/pl"><i className="pl flag"></i></Link><Link to="/en"><i className="gb uk flag"></i></Link></li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
