import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { graphql, useStaticQuery } from 'gatsby';
import Img from "gatsby-image";
import { faFacebookF, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import globals from './../../data/mainWebsite.json';
import Logo from "../images/GSoftware-logo.png";

function Footer({ lang }) {
  const data = useStaticQuery(graphql`
    query {
      wp {
        logoUrl
        smFb
        smIg
        smWhatsapp
      }
      allWpMediaItem {
        edges {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 156, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mediaItemUrl
          }
        }
      }
    }
  `);

  const logo = data.allWpMediaItem.edges.filter(element => element.node.mediaItemUrl === data.wp.logoUrl);

  const translations = {
    pl: {
      label: 'Wybierz język',
      menu: [
        'Start',
        'O nas',
        'Nasze usługi',
        'Projekty',
        'Kontakt'
      ]
    },
    en: {
      label: 'Choose language',
      menu: [
        'Let\'s start!',
        'About us',
        'Services',
        'Our work',
        'Contact'
      ]
    }
  };

  return (
    <footer id="footer">
      <div className="d-flex justify-content-between align-items-lg-center footer-top">
        <a href="#top" className="footer-logo">{ logo[0] ? <Img fluid={logo[0].node.localFile.childImageSharp.fluid} alt="GSoftware Logo" /> : <img src={Logo} alt="GSoftware Logo" />}</a>
        <nav className="footer-nav d-none d-lg-block">
          <ul className="list-inline footer-menu">
            <li className="list-inline-item"><a href="#lets-start">{translations[lang].menu[0]}</a></li>
            <li className="list-inline-item"><a href="#about-us">{translations[lang].menu[1]}</a></li>
            <li className="list-inline-item"><a href="#services">{translations[lang].menu[2]}</a></li>
            <li className="list-inline-item"><a href="#our-work">{translations[lang].menu[3]}</a></li>
            <li className="list-inline-item"><a href="#contact">{translations[lang].menu[4]}</a></li>
          </ul>
        </nav>
        <div className="d-flex flex-row">
          <ul className="list-inline footer-social-media">
            { data.wp.smFb && <li className="list-inline-item"><a href={ data.wp.smFb } aria-label="Facebook"><FontAwesomeIcon icon={faFacebookF} className="footer-social-media-icon" /></a></li> }
            { data.wp.smIg && <li className="list-inline-item"><a href={ data.wp.smIg } aria-label="Instagram"><FontAwesomeIcon icon={faInstagram} className="footer-social-media-icon" /></a></li> }
            { data.wp.smWhatsapp && <li className="list-inline-item"><a href={ data.wp.smWhatsapp } aria-label="WhatsApp"><FontAwesomeIcon icon={faWhatsapp} className="footer-social-media-icon" /></a></li> }
          </ul>
          <div className="footer-top-button d-none d-lg-flex ml-5"><a href="#top" aria-label="Top"><FontAwesomeIcon icon={faChevronUp} /></a></div>
        </div>
      </div>
      <div className="footer-top-button d-lg-none"><a href="#top" aria-label="Top"><FontAwesomeIcon icon={faChevronUp} /></a></div>
      <nav className="footer-nav d-lg-none d-sm-flex justify-content-center">
        <ul className="list-inline footer-menu">
          <li className="list-inline-item"><a href="#lets-start">{translations[lang].menu[0]}</a></li>
          <li className="list-inline-item"><a href="#about-us">{translations[lang].menu[1]}</a></li>
          <li className="list-inline-item"><a href="#services">{translations[lang].menu[2]}</a></li>
          <li className="list-inline-item"><a href="#our-work">{translations[lang].menu[3]}</a></li>
          <li className="list-inline-item"><a href="#contact">{translations[lang].menu[4]}</a></li>
        </ul>
      </nav>
      <div className="d-flex justify-content-between footer-bottom">
        <span>Copyright &copy; {new Date().getFullYear()} GSoftware. All rights reserved.</span>
        <span><a href="#" role="button" id="credits" data-toggle="modal" data-target="#creditsModal">Credits</a></span>
      </div>
      <div className="modal fade" id="creditsModal" tabIndex="-1" role="dialog" aria-labelledby="creditsModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="creditsModalLabel">Credits</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" dangerouslySetInnerHTML={{ __html: globals.creditsModalContent }}>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-dark" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
